// @flow

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import StartIcon from '@material-ui/icons/PlayCircleFilledWhite'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 200,
    color: '#61dafb'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  }
}))

function StartButton ({
  fetching = true,
  onClick = () => console.log('click')
}: {
  fetching?: boolean,
  onClick?: Function
}) {
  const classes = useStyles()

  return (
    <IconButton onClick={onClick}>
      <StartIcon className={classes.icon} />
    </IconButton>
  )
}

export default StartButton
