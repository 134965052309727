// @flow

async function getResult (resultId): mixed {
  const response = await fetch(
    `https://api.jvplabs.app/result?result_id=${resultId}`,
    {
      method: 'GET'
    }
  )

  const json = await response.json()

  if (!response.ok) {
    throw new Error(json.detail)
  }

  return json
}

export default getResult
