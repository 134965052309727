// @flow

import React, { useState, useEffect } from 'react'

import StartButton from '../components/StartButton'
import postCommand from '../api/postCommand'
import getResult from '../api/getResult'

function StartButtonContainer () {
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(null)
  const [status, setStatus] = useState({})
  const [result, setResult] = useState({})

  useEffect(() => {
    const timer = setInterval(async () => {
      const { result_id } = status
      const result = await getResult(result_id)
      if (result.url) {
        setResult(result)
        setFetching(false)
      }
    }, 1000 * 3)

    return () => {
      clearInterval(timer)
    }
  }, [status])

  async function handleClick () {
    setFetching(true)
    setResult({})
    setError(null)
    try {
      const status = await postCommand()
      setStatus(status)
    } catch (error) {
      setFetching(false)
      setError(error.message)
      setFetching(false)
    }
  }

  const { url } = result

  const statusMessage = fetching ? 'Recording' : 'Ready'
  const errorMessage = 'Device not available'

  if (error) {
    console.log(`Error: ${error}`)
  }

  return (
    <>
      <StartButton fetching={fetching} onClick={handleClick} />
      <p>{error ? errorMessage : statusMessage}</p>
      {url && (
        <p>
          <a
            href={url}
            target='_blank'
            rel='noreferrer'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            Download result
          </a>
        </p>
      )}
    </>
  )
}

export default StartButtonContainer
