// @flow

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import StartButtonContainer from './containers/StartButtonContainer'

const useStyles = makeStyles((theme) => ({
  app: {
    textAlign: 'center',
  },
  logo: {
    height: '40vmin',
    pointerEvents: 'none',
  },
  header: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: 'white',
  },
  link: {
    color: '#61dafb',
  }
}))

function App() {
  const classes = useStyles()

  return (
    <div className={classes.app}>
      <header className={classes.header}>
        <p>
          JVP Home
        </p>
        <StartButtonContainer className={classes.logo} />
      </header>
    </div>
  );
}

export default App
