// @flow

async function postCommand (): mixed {
  const response = await fetch('https://api.jvplabs.app/command', {
    method: 'POST',
    body: JSON.stringify({ params: { fps: 1 } })
  })
  console.log(JSON.stringify({ params: { fps: 1 } }))
  const json = await response.json()

  if (!response.ok) {
    throw new Error(json.detail)
  }

  return json
}

export default postCommand
